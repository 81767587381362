.wrapper {
  @apply relative w-full p-2 z-[99];
}

.header {
  @apply flex flex-row items-center justify-between;
}

.list {
  @apply absolute left-0 top-[20px] max-h-80 w-full overflow-auto rounded bg-white shadow-shadow-04;
}

.listItem {
  @apply flex h-12 w-full cursor-pointer items-center pb-2 pl-4 pr-3 pt-2 leading-6 hover:bg-interfaceColor-10;
}

.listModal {
  @apply h-full w-full overflow-auto;
}

.listItemModal {
  @apply flex h-12 w-full cursor-pointer items-center pb-2 pl-4 pr-3 pt-3 leading-6 hover:bg-interfaceColor-10;
}

.selectedItemWhenClosed {
  @apply w-[80px] h-[29px] rounded-sm bg-interfaceColor-20 flex flex-row text-center justify-start py-[0.1rem] px-[0.1rem] mr-[2px] gap-[3px]
}
