.filterLabel {
  @apply mr-9 pr-1 text-24 font-normal leading-9 text-interfaceColor-100;
}

.filterAppliedText {
  @apply text-16 tracking-[0.01em] text-interfaceColor-100;
}

.filterClearAllButton {
  @apply ml-4 border-b border-b-interfaceColor-100 text-16 tracking-[0.01em] text-interfaceColor-100 hover:border-b-primaryBrandColor-100;
}

.filterSectionLabel {
  @apply mb-2 text-20 font-normal tracking-[0.01em] text-interfaceColor-100;
}

.filterContent {
  @apply overflow-auto;
}

.filterButton {
  @apply flex ml-[1px] w-[310px] border-[1px] rounded-md justify-start content-center border-interfaceColor-20 p-2;
}
.buttonContent {
  @apply w-full px-[5px] justify-between flex-row flex content-center;
}
.filterSelectedItemsWrapper {
  @apply flex w-[310px] flex-row border-[1px] rounded-md justify-between content-center border-interfaceColor-20 py-[0.1rem];
}

.filterSelectedItemsBox {
  @apply flex flex-row content-start text-center justify-center border-interfaceColor-10 pl-1 p-1 gap-[0.1rem];
}
.expandDropdownWrapper{
  @apply flex flex-row gap-0 content-center justify-between cursor-pointer w-[53px] text-center pt-1.5 pr-3;
}
