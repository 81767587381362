.downloadBtn {
  @apply mb-5 w-full lg:mb-0 lg:mr-5 lg:w-auto;
}

.closeBtn {
  @apply w-full lg:w-auto;
}

.labelForAllData {
  @apply ml-[37px] text-14 text-interfaceColor-80;
}

.labelForFilterData {
  @apply ml-[37px] text-14 text-interfaceColor-80;
}

.headerText {
  @apply text-16 font-semibold leading-6 text-interfaceColor-100;
}

.contentClasses {
  @apply flex flex-col !items-start !justify-start bg-white;
}
