.box {
  @apply col-span-3 bg-white lg:col-span-1 xl:col-span-1 p-4 sm:p-6 shadow flex flex-col min-h-[200px];
}

.severity {
  @apply mt-2 sm:mt-3 rounded-full justify-center items-center flex h-8 md:h-9 gap-1;
}

.name {
  @apply mt-4 sm:mt-6 text-16 md:text-20 flex-grow;
}

.buttonWrapper {
  @apply mt-4 sm:mt-6;
}

.icons {
  @apply h-4 w-4;
}

.Low {
  @apply border-successColor-100 bg-successColor-10 border border-successColor-100 w-[125px] text-successColor-100;
}

.Medium {
  @apply border-warningColor-100 bg-warningColor-10 border border-warningColor-100 w-[150px];
}

.High {
  @apply border-errorColor-30 bg-errorColor-10 border border-errorColor-100 w-[130px] text-errorColor-100;
}

.Unclassifed {
  @apply border-infoColor-30 bg-infoColor-10 border border-infoColor-100 w-[180px] text-infoColor-100;
}