.info {
  @apply border-infoColor-30 bg-infoColor-10 text-interfaceColor-100;
}

.warning {
  @apply border-warningColor-100 bg-warningColor-10 text-interfaceColor-100;
}

.error {
  @apply border-errorColor-30 bg-errorColor-10 text-interfaceColor-100;
}

.success {
  @apply border-successColor-30 bg-successColor-10 text-interfaceColor-100;
}

.highContrast {
  @apply border-interfaceColor-100 bg-interfaceColor-100 text-white;
}

.wrapper {
  @apply relative mt-2 flex w-full items-start justify-between gap-[8px] rounded-[4px] border p-4 shadow-shadow-03 lg:mx-auto lg:mt-4 lg:w-auto lg:min-w-[560px] lg:max-w-[630px];
}

.title {
  @apply text-16 font-semibold;
}

.message {
  @apply ml-1 text-16;
}

.errorMessage {
  @apply ml-6 text-16;
}

.closeBtn {
  @apply absolute right-[20px] flex h-6 w-6 flex-shrink-0 items-center justify-center;
}
