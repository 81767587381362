.listWrapper {
  @apply absolute flex min-w-[16rem] max-w-[18rem] list-none flex-col items-start rounded-[0.25rem] bg-white p-1 text-interfaceColor-100 shadow-shadow-04;
}

.textHeaderWrapper {
  @apply inline-flex h-[50px] flex-row border-l-[1px] border-t-[1px] border-interfaceColor-20 bg-white pb-2 pl-4 pr-3 pt-2 text-interfaceColor-100	hover:bg-interfaceColor-10;
}

.textHeaderButton {
  @apply flex items-center focus-visible:outline-none;
}

.textHeaderIcon {
  @apply h-4 w-4 pl-1;
}

.iconHeaderButton {
  @apply flex h-8 w-8 items-center justify-center rounded-full bg-interfaceColor-30;
}

.defaultItemWrapper {
  @apply flex h-12 w-full cursor-pointer flex-row items-center justify-between pb-2 pl-4 pr-3 pt-2 leading-6 hover:bg-interfaceColor-10;
}

.defaultItemIcon {
  @apply h-6 w-6 p-1;
}

.contextItemWrapper {
  @apply z-10 flex w-full cursor-pointer flex-row items-start gap-[4px] bg-white p-2;
}

.contextItemHovered {
  @apply rounded-[0.25rem] hover:bg-interfaceColor-30;
}

.contextItemLabel {
  @apply w-full text-16 leading-6;
}

.contextItemDisabled {
  @apply cursor-not-allowed text-interfaceColor-70;
}

.contextItemIconWrapper {
  @apply flex h-6 w-6 items-center justify-center;
}

.dropdownModalButtonWrapper {
  @apply inline-flex h-12 flex-row border-0 border-interfaceColor-20 bg-white pb-2 pl-4 pr-3 pt-2 text-interfaceColor-100 hover:bg-interfaceColor-10 md:border-l-2;
}

.dropdownModalButton {
  @apply flex items-center focus-visible:outline-none;
}

.dropdownModalListItem {
  @apply flex w-full cursor-pointer flex-row items-center justify-between pb-4 pl-5 pr-5 pt-4 leading-6 hover:bg-interfaceColor-10;
}
