.drawerEnter {
  @apply translate-x-full transform;
}

.drawerEnterActive {
  @apply translate-x-0 transform;
}

.drawerExit {
  @apply translate-x-0 transform;
}

.drawerExitActive {
  @apply translate-x-full transform;
}

.drawerEnterActive,
.drawerExitActive {
  @apply transition-transform duration-300;
}

.wrapper {
  @apply fixed right-0 top-0 z-50 flex h-full w-full flex-col bg-white p-0 md:w-1/2 lg:w-[394px];
}

.headerWrapper {
  @apply relative mb-2 flex justify-between pb-1 pl-8 pr-8 pt-[1.5rem];
}

.headerText {
  @apply text-28 font-normal leading-9 text-interfaceColor-100;
}

.content {
  @apply px-5 py-5 md:px-8 md:pb-6 md:pt-0;
}

.footerWrapper {
  @apply absolute bottom-0 z-20 mt-auto h-auto w-full bg-interfaceColor-5 px-8 pb-3 pt-3 md:h-[85px] md:pb-8 md:pt-5;
}

.actionBtns {
  @apply grid w-full grid-cols-2 gap-4 gap-y-2;
}

.overlay {
  @apply fixed left-0 top-0 z-[49] h-full w-full bg-gray-900 bg-opacity-25;
}
