.pageGrid {
    @apply mx-1 mt-0 grid gap-8 py-6 pt-0 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4;
  }
  
  .lineChartTile {
    @apply col-span-2 h-auto !w-auto bg-white md:col-span-3 md:h-[16.6rem] lg:col-span-2 xl:col-span-2;
  }
  
  .doughnutChartTile {
    @apply col-span-2 h-auto bg-white md:h-[16.6rem] lg:col-span-1 xl:col-span-1;
  }
  
  .textTile {
    @apply col-span-2 h-auto !w-auto bg-white md:h-[16.6rem] lg:col-span-1 xl:col-span-1;
  }
  
  .textTileContainer {
    @apply flex w-full flex-col justify-start gap-y-3 align-baseline;
  }
  
  .textTileItem {
    @apply flex flex-row items-baseline gap-x-2;
  }
  
  .textTileItemIcon {
    @apply flex-shrink-0 text-primaryBrandColor-150;
  }
  