.wrapper {
  @apply flex flex-col items-center justify-center text-center bg-white pb-20 pl-5 pr-5 pt-20 text-interfaceColor-100 lg:rounded-[4px] lg:shadow-shadow-02;
}

.logo {
  @apply mb-2 h-20 w-16;
}

.headerTxt {
  @apply text-24 leading-8;
}

.contentTxt {
  @apply mt-2 text-16 leading-6;
}
