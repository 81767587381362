.locationCardContent {
  @apply flex justify-end items-center;
}

.locationCardAddressWrapper {
  @apply order-1 ml-0 flex items-center justify-center text-interfaceColor-80 md:order-2 md:ml-1;
}

.locationFullAddress {
  @apply relative w-[270px] overflow-hidden whitespace-nowrap md:w-[303px] lg:w-[470px];
}

.locationFullAddress::after {
  content: '';
  @apply absolute right-0 top-0 block h-5 w-12 bg-gradient-to-r from-transparent to-white;
}

.locationName {
  @apply relative w-[290px] overflow-hidden whitespace-nowrap md:w-[445px] lg:w-[612px];
}

.locationName::after {
  content: '';
  @apply absolute right-0 top-[5px] block h-6 w-12 bg-gradient-to-r from-transparent to-white;
}
