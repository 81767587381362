.details-ms-page ol {
  counter-reset: item;
  list-style-type: none;
}

.details-ms-page ol > li {
  counter-increment: item;
  margin-bottom: 10px;
}

.details-ms-page ol > li::before {
  content: counter(item) '. ';
  font-weight: 600 !important;
  margin-right: 8px;
}

.details-ms-page ol > li ol {
  counter-reset: subitem;
  list-style-type: none;
  margin-left: 22px;
}

.details-ms-page ol > li ol > li {
  counter-increment: subitem;
  margin-bottom: 6px;
}

.details-ms-page ol > li ol > li::before {
  content: counter(subitem, lower-alpha) '. ';
  font-weight: 600 !important;
  margin-right: 8px;
}

.details-ms-page a {
  text-decoration: underline;
}

.details-ms-page ul {
  list-style-type: none;
}

.details-ms-page ul > li {
  position: relative;
  margin-bottom: 10px;
}

.details-ms-page ul > li::before {
  content: '\2022';
  color: #00ec7b;
  font-size: 1.5em;
  margin-right: 12px;
}

.details-ms-page ul ul {
  list-style-type: none;
}

.details-ms-page ul ul > li {
  position: relative;
  margin-bottom: 5px;
}

.details-ms-page ul ul > li::before {
  content: '';
  border: 2px solid black;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-left: 22px;
}
