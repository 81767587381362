.list {
  @apply absolute left-0 top-[20px] max-h-80 w-full overflow-auto rounded bg-white shadow-shadow-04;
}

.listModal {
  @apply w-full overflow-hidden;
}

.listItemModal {
  @apply flex h-12 w-full cursor-pointer items-center leading-6;
}
