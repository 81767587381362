.sidebar-enter {
  transform: translateX(100%);
}

.sidebar-enter-active {
  transform: translateX(0%);
}

.sidebar-exit {
  transform: translateX(0%);
}

.sidebar-exit-active {
  transform: translateX(100%);
}

.sidebar-enter-active,
.sidebar-exit-active {
  transition: transform 300ms ease-out;
}
