.treeView {
  @apply relative pl-[1px];
}

.treeViewCard {
  @apply mb-4 w-full snap-start md:snap-center xl:w-[700px];
}

.simpleTreeViewCard {
  @apply mb-2 w-fit max-w-[500px] cursor-pointer bg-interfaceColor-10 px-2 py-1 shadow-none transition-all duration-200 hover:bg-interfaceColor-20;
}

.treeRootNode {
  @apply mb-6 sm:mx-2 lg:mx-0;
}

.simpleTreeRootNode {
  @apply mb-3;
}

.treeRootNodeSimple::after {
  content: '';
  @apply absolute bottom-[43px] left-[0.9rem] h-[5px] w-[5px] animate-fadeIn rounded-full bg-primaryBrandColor-100;
}

.simpleTreeRootNodeSimple::after {
  @apply bottom-[0.75rem] left-[0.45rem] h-[4px] w-[4px];
}

.treeRootNode > .treeViewCard::after {
  @apply hidden;
}

.treeRootNode > .treeViewCard::before {
  @apply hidden;
}

.treeViewCard::after {
  content: '';
  @apply absolute -left-[0.66rem] top-[36px] h-[20px] w-[7px] -rotate-90 animate-fadeIn rounded-tl-[50px] border-l-[0.1rem] border-t-[0.1rem] border-primaryBrandColor-100 md:top-[33px];
}

.treeViewCard::before {
  content: '';
  @apply absolute left-[1px] top-[46px] h-[5px] w-[5px] -translate-x-1/2 transform animate-fadeIn rounded-full bg-primaryBrandColor-100 md:top-[43px];
}

.simpleTreeViewCard::before {
  @apply top-[1.074rem] h-[4px] w-[4px];
}

.simpleTreeViewCard::after {
  @apply -left-[0.3rem] top-[0.788rem] h-[0.7rem] w-[0.25rem];
}

.treeViewCardHeader {
  @apply w-full;
}

.treeViewCardHeaderContent {
  @apply relative flex w-full flex-col items-start justify-between text-18 md:static md:flex-row md:items-center md:text-20;
}

.simpleTreeViewCardHeaderContent {
  @apply text-16;
}

.treeViewActions {
  @apply mt-2 flex flex-row items-center justify-center px-2 leading-[20px] md:mt-1;
}

.treeViewActionsText {
  @apply ml-1 hidden md:block;
}

.visibilityText {
  @apply ml-1 md:block;
}

.treeNodeExpandButton {
  @apply absolute bottom-0 right-0 flex items-center justify-center px-1 py-[1px] text-14 md:relative md:px-3 md:py-1 md:pr-1 md:text-16;
}

.treeNodeSelectedIcon {
  @apply ml-2 mt-1 h-4 w-4;
}

.treeViewHeightChild {
  @apply absolute left-[16px] top-[91px] h-[calc(100%-135px)] max-h-[calc(100%-135px)] w-[0.1rem] animate-draw bg-primaryBrandColor-100;
}

.simpleTreeViewHeightChild {
  @apply absolute left-[9px] top-[32px] h-[calc(100%-45px)] max-h-[calc(100%-45px)] w-[0.1rem] animate-draw bg-primaryBrandColor-100;
}

.treeViewCardSelected {
  @apply !bg-interfaceColor-100 !text-white;
}

.groupName {
  @apply relative w-[255px] overflow-hidden whitespace-nowrap md:w-[440px] lg:w-[490px];
}

.groupName::after {
  content: '';
  @apply absolute right-0 top-[5px] block h-6 w-12 bg-gradient-to-r from-transparent to-white;
}
