.cardHeader {
  @apply mb-3 w-full text-20 leading-7;
}

.cardHeaderContainer {
  @apply flex w-full flex-row items-baseline justify-between gap-x-1;
}

.dropdownTextHeader {
  @apply !h-8 rounded-[24px] !bg-interfaceColor-10 text-16;
}

.cardContent {
  @apply mt-3 flex h-full w-full items-center justify-between;
}

.cardDetails {
  @apply flex w-full flex-col transition-all duration-200;
}

.chartContainer {
  @apply flex w-full flex-row items-center gap-x-3 py-2 lg:h-[10rem];
}

.chart {
  @apply md:h-[10rem] md:w-[10rem] lg:h-[8.25rem] lg:w-[8.25rem];
}

.chartLegendItem {
  @apply mb-2 flex flex-row items-baseline gap-x-1;
}

.chartLegendDot {
  @apply h-3 w-3 flex-shrink-0 rounded-full;
}
