.cardHeader {
  @apply mb-3 w-full text-20 leading-7;
}

.cardHeaderContainer {
  @apply flex w-full flex-row items-baseline justify-between gap-x-1;
}

.dropdownTextHeader {
  @apply !h-8 rounded-[24px] !bg-interfaceColor-10 text-16;
}

.cardContent {
  @apply flex h-full w-full items-center justify-between;
}

.cardContainer {
  @apply flex w-full flex-col transition-all duration-200;
}

.cardDetails {
  @apply flex h-[8.25rem] w-full flex-row items-center gap-x-3 bg-interfaceColor-5 px-3 py-2;
}

.cardDetailsNumber {
  @apply text-[80px] font-normal tracking-tighter text-interfaceColor-100;
}

.cardDetailsPercentage {
  @apply mt-11 flex items-center gap-x-2 self-auto text-16 tracking-[0.01rem] text-successColor-100;
}

.cardDetailsPercentageZero {
  @apply mt-11 flex items-center gap-x-2 self-auto text-16 tracking-[0.01rem] text-interfaceColor-80;
}

.zeroLine {
  @apply h-[1px] w-[14px] bg-interfaceColor-80;
}

.bottomLabelContainer {
  @apply bg-interfaceColor-5 pb-2 pl-2 text-14;
}
