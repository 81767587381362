  .container {
    height: 297mm;
    width: 210mm;
    margin-top: 15px;
    margin-left: 15px;
    padding-left: 35px;
    padding-right: 45px;
    position: relative;
    display: flex;
    justify-items: center;
    justify-content: center;
    flex-direction: column;
    page-break-before: always;
  }
  
  .logo {
    position: absolute;
    top: 50px;
    left: 40px;
    height: 40px;
  }
  
  .title {
    font-size: 72px;
    break-inside: avoid;
    margin-left: 0x;
  }
  
  .tenantName {
    font-size: 22px;
    break-inside: avoid;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  
  .date {
    font-size: 20px;
    break-inside: avoid;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  
  .subReports {
    break-inside: avoid;
    padding-left: 15px;
  }
  
  .subReportItem {
    font-size: 25px;
    break-inside: avoid;
    display: block;
    margin-bottom: 5px;
  }
  
  .logoCrop {
    position: absolute;
    bottom: -26px;
    right: -14px;
  }
  
  .page {
    height: 297mm;
    width: 210mm;
    margin-top: 15px;
    margin-left: 15px;
    padding-left: 35px;
    padding-right: 45px;
    position: relative;
  }
  
  .pageTitle {
    font-size: 46px;
    break-inside: avoid;
    page-break-before: always;   
    /* margin-left: 15px; */
    margin-left: -15px;
  }
  
  .components {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
  }
  
  .componentItem {
    font-size: 20px;
    break-inside: avoid;
    display: block;
    margin-bottom: 5px;
  }
  
  .footer {
    position: absolute;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    left: 20px;
  }
  
  .footerLogo {
    margin-right: 12px;
  }
  
  .pageNumber {
    position: absolute;
    bottom: 0;
    right: 20px;
  }
  
  .error {
    color: red;
    padding: 20px;
  }
  
  .loading {
    padding: 20px;
  }
  
:global(.page),
:global(.components) {
  display: block !important;
  visibility: visible !important;
  opacity: 1 !important;
}