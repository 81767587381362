.cardWrapper {
  @apply bg-interfaceColor-5 pt-4;
}

.showCheckboxesWrapper {
  @apply fixed bottom-0 left-0 flex w-full flex-row items-center gap-x-2 bg-interfaceColor-20 px-5 py-4;
}

.paginationWrapper {
  @apply mt-4 flex items-center justify-between bg-white p-5;
}