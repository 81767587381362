.overlayWrapper {
  @apply fixed right-0 top-0 z-50 flex h-full w-full flex-col overflow-auto bg-interfaceColor-5;
}

.overlay {
  @apply relative items-center bg-interfaceColor-5 px-5 pb-4 pt-8 lg:px-6 lg:pt-6 xl:px-10;
}

.headerWrapper {
  @apply relative flex w-[calc(100%-45px)] justify-between overflow-hidden whitespace-nowrap text-24 leading-8 text-interfaceColor-100 md:leading-[44px] lg:text-36 lg:leading-[48px] xl:text-40;
}

.headerWrapper::after {
  content: '';
  @apply absolute right-0 top-0 block h-12 w-14 bg-gradient-to-r from-transparent to-interfaceColor-5;
}

.closeBtn {
  @apply absolute right-[10px] top-[30px] h-[36px] w-[36px] lg:right-[30px];
}

.headerText {
  @apply mb-2 mt-2 text-18 md:mb-3 md:mt-3 md:text-20;
}

.headerBtnLabel {
  @apply w-full md:w-auto;
}

.content {
  @apply mb-[64px] px-1 pb-4 pt-8 md:px-6 lg:mb-[100px] lg:pt-10 xl:px-8;
}

.footerWrapper {
  @apply fixed bottom-0 left-0 w-full bg-interfaceColor-100 pb-4 pl-5 pr-5 pt-4 md:p-4 lg:p-7;
}

.footerContent {
  @apply flex;
}

.footerText {
  @apply relative flex flex-grow items-center overflow-hidden whitespace-nowrap text-16 leading-6 text-white;
}

.footerText::after {
  content: '';
  @apply absolute right-0 top-[3px] block h-8 w-14 bg-gradient-to-r from-transparent to-interfaceColor-100;
}

.footerBtn {
  @apply mr-4 items-center md:flex;
}

.overlayEnter {
  @apply translate-y-full transform;
}

.overlayEnterActive {
  @apply translate-y-0 transform;
}

.overlayExit {
  @apply translate-y-0 transform;
}

.overlayExitActive {
  @apply translate-y-full transform;
}

.overlayEnterActive,
.overlayExitActive {
  @apply transition-transform duration-300;
}
