.cardHeader {
  @apply mb-3 w-full text-20 leading-7;
}

.cardHeaderContainer {
  @apply flex w-full flex-row items-baseline justify-between;
}

.headerWrapper {
  @apply mt-2 flex flex-col md:flex-row;
}

.dropdownWrapper {
  @apply flex gap-x-4;
}

.dateRange {
  @apply text-14;
}

.dropdownTextHeader {
  @apply !h-8 rounded-[24px] !bg-interfaceColor-10 text-16;
}

.compareLabel {
  @apply text-16 font-normal tracking-[0.01rem] text-interfaceColor-100;
}

.cardContent {
  @apply flex h-full w-full items-center justify-between;
}

.cardDetails {
  @apply flex w-full flex-col items-center gap-x-3 transition-all duration-200;
}

.chartLegendWrapper {
  @apply mt-3 flex w-full flex-row items-center gap-x-4;
}

.chartLegendItem {
  @apply flex flex-row items-baseline gap-x-2;
}

.chartLegendDot {
  @apply h-3 w-3 flex-shrink-0 rounded-full;
}
