.info {
  @apply border-infoColor-30 bg-infoColor-10;
}

.warning {
  @apply border-warningColor-100 bg-warningColor-10;
}

.error {
  @apply border-errorColor-30 bg-errorColor-10;
}

.success {
  @apply border-successColor-30 bg-successColor-10;
}

.icons {
  @apply absolute top-[20px] h-4 w-4;
}

.wrapper {
  @apply relative flex flex-col rounded-[4px] border px-4 py-4 text-interfaceColor-100;
}

.alertBtn {
  @apply absolute end-2 top-7 -me-0.5 -mt-3 flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full text-interfaceColor-100 transition-colors duration-200;
}
