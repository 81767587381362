.wrapper {
  inset: 0;
  position: fixed;
  top: 0;
  height: 100vh;
  background: rgba(40, 43, 47, 0.25);
}

.modal {
  transition: opacity 300ms ease-out, transform 300ms ease-out;
  z-index: 999;
  transform: translateY(100%);
}

.modal-enter-done {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0%);
}

.modal-exit {
  opacity: 0;
  transform: translateY(100%);
}

.truncate-header-modal h3::after {
  content: '';
  position: absolute;
  right: 0;
  top: 5px;
  display: block;
  height: 40px;
  width: 40px;
  background-image: linear-gradient(to right, transparent, white);
}
