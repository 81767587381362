.cardHeader {
  @apply mb-3 w-full text-20 leading-7;
}

.cardHeaderContainer {
  @apply flex w-full flex-row items-baseline justify-between;
}

.chartContainer {
  @apply flex w-full flex-wrap items-center;
}

.dropdownTextHeader {
  @apply !h-8 rounded-[24px] !bg-interfaceColor-10 text-16;
}

.cardContent {
  @apply flex h-full w-full items-center justify-between;
}

.cardDetails {
  @apply flex w-full flex-col items-center gap-x-3 transition-all duration-200;
}
