.ccCheckbox {
  @apply absolute opacity-0;
}

.ccCheckbox + label {
  @apply relative inline-flex items-center p-0;
}

.ccCheckbox + label:before {
  content: '';
  @apply mr-3 inline-flex h-6 w-6 flex-shrink-0 rounded-[4px] border border-interfaceColor-40 bg-white transition-colors;
}

.ccCheckbox:hover + label:before {
  @apply border-interfaceColor-60;
}

.ccCheckbox:focus + label:before {
  @apply focus:shadow-focus focus-visible:outline-none;
}

.ccCheckbox:checked + label:before {
  @apply border-primaryBrandColor-100 bg-primaryBrandColor-100 transition-colors;
}

.ccCheckbox:disabled + label {
  @apply text-interfaceColor-70;
}

.ccCheckbox:disabled + label:before {
  @apply border-interfaceColor-30 bg-interfaceColor-30 shadow-none;
}

.ccCheckbox:checked + label:after {
  content: url('data:image/svg+xml,%3Csvg%20width%3D%2210%22%20height%3D%228%22%20viewBox%3D%220%200%2010%208%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M1%204.33333L3%207L9%201%22%20stroke%3D%22%23282B2F%22%20stroke-width%3D%221.2%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fsvg%3E');
  @apply absolute left-[12px] top-[46%] -translate-x-1/2 -translate-y-1/2 transform;
}

.ccCheckboxDefaultSelected + label:after {
  content: url('data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cg%20id%3D%22Frame%22%3E%3Cg%20id%3D%22Group%22%3E%3Cpath%20id%3D%22Vector%22%20d%3D%22M11%208H5%22%20stroke%3D%22%23282B2F%22%20stroke-width%3D%221.14286%22%20stroke-linecap%3D%22round%22%20stroke-linejoin%3D%22round%22%2F%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E');
  @apply absolute left-[12px] top-[60%] -translate-x-1/2 -translate-y-1/2 transform;
}

.ccCheckboxDefaultSelected + label:before {
  @apply border-primaryBrandColor-100 bg-primaryBrandColor-100 transition-colors;
}

.ccCheckboxError {
  @apply my-2 text-end text-14 text-errorColor-100;
}

.ccCheckboxLabel {
  @apply text-16 text-interfaceColor-100 relative;
}

.fadeOut {
  @apply absolute right-0 top-0 block h-5 w-12 bg-gradient-to-r from-transparent to-white;
}

.ccCheckboxWrapper {
  @apply flex items-center w-full;
}
