:root {
  --tooltip-margin: 15px;
  --arrowIcon: url('data:image/svg+xml,<svg width="8" height="22" viewBox="0 0 8 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.707105 10.2929L6.24264 4.75736C7.36786 3.63214 8 2.10602 8 0.514719L8 21.4853C8 19.894 7.36786 18.3679 6.24264 17.2426L0.707106 11.7071C0.316582 11.3166 0.316581 10.6834 0.707105 10.2929Z" fill="%23282B2F"/></svg>');
}

.wrapper {
  @apply relative inline-block cursor-pointer;
}

.tooltip {
  @apply absolute z-40 w-48 rounded bg-interfaceColor-100 pb-2 pl-3 pr-3 pt-2 text-16 leading-5 text-white shadow-shadow-05;
}

.tooltip::before {
  @apply absolute;
  content: var(--arrowIcon);
}

.tooltip.top {
  @apply bottom-[calc(100%+var(--tooltip-margin))] left-1/2 -translate-x-1/2 transform;
}

.tooltip.top::before {
  @apply bottom-[-16px] left-1/2 -translate-x-1/2 -rotate-90 transform;
}

.tooltip.right {
  @apply left-[calc(100%+var(--tooltip-margin))] top-1/2 -translate-y-1/2 transform;
}

.tooltip.right::before {
  @apply left-[-8px] top-1/2 -translate-y-1/2 transform;
}

.tooltip.bottom {
  @apply left-1/2 top-[calc(100%+var(--tooltip-margin))] -translate-x-1/2 transform;
}

.tooltip.bottom::before {
  @apply left-1/2 top-[-16px] translate-x-[-50%] rotate-90 transform;
}

.tooltip.left {
  @apply left-auto right-[calc(100%+var(--tooltip-margin))] top-1/2 -translate-y-1/2 transform;
}

.tooltip.left::before {
  @apply right-[-8px] top-1/2 -translate-y-1/2 rotate-180 transform;
}

.tooltip.bottomLeft {
  @apply left-0 top-[calc(100%+var(--tooltip-margin))];
}

.tooltip.bottomLeft::before {
  @apply left-[15px] top-[-16px] -translate-x-1/2 rotate-90 transform;
}

.tooltip.bottomRight {
  @apply right-0 top-[calc(100%+var(--tooltip-margin))];
}

.tooltip.bottomRight::before {
  @apply right-[15px] top-[-16px] -translate-x-1/2 rotate-90 transform;
}

.tooltip.topLeft {
  @apply bottom-[calc(100%+var(--tooltip-margin))] left-0;
}

.tooltip.topLeft::before {
  @apply bottom-[-16px] left-[19px] -translate-x-1/2 -rotate-90 transform;
}

.tooltip.topRight {
  @apply bottom-[calc(100%+var(--tooltip-margin))] right-0;
}

.tooltip.topRight::before {
  @apply bottom-[-16px] right-[15px] -translate-x-1/2 -rotate-90 transform;
}
