.wrapper {
  @apply relative w-full rounded bg-white shadow-shadow-02;
}

.headerListItem {
  @apply flex items-center justify-between border-b-[1px] border-interfaceColor-20 px-4 py-3 font-semibold leading-6;
}

.listItem {
  @apply flex cursor-pointer items-center justify-between px-4 py-3 text-interfaceColor-80;
}

.listChildItem {
  @apply flex cursor-pointer items-center justify-between px-4 py-2 text-14 text-interfaceColor-80;
}

.listChildItem:hover {
  @apply font-semibold text-interfaceColor-100;
}

.listChildItem:hover svg {
  @apply text-primaryBrandColor-150;
}

.listItem:hover svg {
  @apply text-primaryBrandColor-150;
}

.linkItem {
  @apply flex w-full items-center justify-between;
}

.linkItem:hover {
  @apply font-semibold text-interfaceColor-100;
}
