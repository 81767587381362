.pageGrid {
  @apply mx-1 mb-2 mt-7 grid grid-cols-1 gap-8 py-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4;
}

.lineChartTile {
  @apply col-span-2 h-auto !w-auto bg-white md:col-span-3 md:h-[19.3rem] lg:col-span-2 xl:col-span-2;
}

.doughnutChartTile {
  @apply col-span-2 h-auto bg-white md:h-[19.3rem] lg:col-span-1 xl:col-span-1;
}

.textTile {
  @apply col-span-2 h-auto !w-auto bg-white md:h-[19.3rem] lg:col-span-1 xl:col-span-1;
}

.textTileContainer {
  @apply flex w-full flex-col justify-start gap-y-1 align-baseline;
}

.textTileItem {
  @apply flex flex-row items-baseline gap-x-2;
}

.textTileItemIcon {
  @apply flex-shrink-0 text-primaryBrandColor-150;
}

.textTileLinkItem {
  @apply flex flex-col gap-y-1;
}

.noResultButton {
 @apply inline-flex h-10 w-full flex-shrink-0 items-center justify-center rounded-[24px] bg-interfaceColor-90 px-4  py-2 text-16 font-normal leading-6 tracking-[0.01em] text-white transition duration-300 ease-in-out hover:bg-interfaceColor-150 hover:text-interfaceColor-10 focus:shadow-focus md:w-auto lg:w-auto
}
