.pageContainer {
  @apply relative h-full w-[calc(100vw-40px)] snap-x overflow-auto overflow-x-auto md:contents md:w-full md:overflow-x-hidden md:overflow-y-hidden;
}

.pageGrid {
  @apply m-1 grid max-w-full grid-flow-row grid-cols-6 gap-8 xl:max-w-[1440px];
}

.header {
  @apply mb-[28px] mt-[40px] text-28;
}
