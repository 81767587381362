.wrapper {
  @apply w-full lg:w-[704px] lg:ml-[35px];
}

.header {
  @apply mb-5 text-32 md:text-36 lg:text-40;
}

p {
  @apply leading-6;
}

.list {
  @apply mb-2 mt-2;
}

.list li {
  @apply p-2;
}

.list li::before {
  content: '\2022';
  @apply mr-2 font-bold text-primaryBrandColor-150;
}

.list span {
  @apply font-bold;
}
