.cardsWrapper {
  @apply m-1 grid max-w-full grid-flow-row grid-cols-6 gap-8 xl:max-w-[1440px];
}

.cardWrapper {
  @apply flex w-full md:w-[49%] lg:w-[32%];
}

.cardContentWrapper {
  @apply flex !h-full w-full flex-col justify-between md:w-auto;
}

.iconWrapper {
  @apply mb-2 flex h-10 w-10 items-center justify-center rounded-full bg-interfaceColor-150 text-primaryBrandColor-100;
}

.cardHeader {
  @apply text-2xl lg:text-3xl;
}
