.wrapper {
  @apply relative w-full rounded border border-interfaceColor-40 p-2;
}

.header {
  @apply flex flex-row items-center justify-between;
}

.list {
  @apply absolute left-0 top-[45px] w-full rounded bg-white shadow-shadow-04;
}

.listItem {
  @apply flex h-12 w-full cursor-pointer items-center pb-2 pl-4 pr-3 pt-2 leading-6 hover:bg-interfaceColor-10;
}

.disabled {
  @apply cursor-not-allowed opacity-50;
}
