.ccRadioButtonWrapper {
  @apply flex items-center;
}

.ccRadioButton {
  @apply absolute opacity-0;
}

.ccRadioButton + label {
  @apply relative inline-flex cursor-pointer items-center p-0;
}

.ccRadioButton + label:before {
  content: '';
  @apply mr-3 inline-flex h-6 w-6 flex-shrink-0 rounded-full border border-interfaceColor-40 bg-white transition-colors;
}

.ccRadioButton:hover + label:before {
  @apply border-interfaceColor-60;
}

.ccRadioButton:focus + label:before {
  @apply focus:shadow-focus focus-visible:outline-none;
}

.ccRadioButton:checked + label:before {
  @apply border-primaryBrandColor-100 bg-primaryBrandColor-100 transition-colors;
}

.ccRadioButton:checked + label:after {
  content: '';
  @apply absolute left-[6px] h-3 w-3 rounded-full bg-black;
}

.ccRadioButton:disabled + label {
  @apply text-interfaceColor-70;
}

.ccRadioButton:disabled + label:before {
  @apply border-interfaceColor-30 bg-interfaceColor-30 shadow-none;
}

.ccRadioButton:disabled:checked + label:after {
  content: '';
  @apply absolute left-[6px] h-3 w-3 rounded-full bg-interfaceColor-70;
}

.ccRadioButtonError {
  @apply my-2 text-end text-14 text-errorColor-100;
}

.ccRadioButtonLabel {
  @apply text-16 text-interfaceColor-100;
}
