@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.cdnfonts.com/css/roobert?styles=49579,49577,49581,49571,49573');
@import '../node_modules/swiper/swiper-bundle.css';
body {
  font-family: 'Roobert', sans-serif;
}

.status {
  @apply mr-2 inline-flex h-2 w-2 rounded-full;
}

.status-unknown,
.status-archived {
  @apply bg-interfaceColor-90;
}

.status-archived {
  @apply text-interfaceColor-80;
}

.status-inactive,
.status-expired,
.status-deleted,
.status-disabled {
  @apply bg-errorColor-100;
}

.status-active,
.status-enabled,
.status-enforced {
  @apply bg-successColor-100;
}

.status-address {
  @apply border border-solid border-interfaceColor-80;
}

.status-warning,
.status-enabledmfa {
  @apply bg-warningColor-100;
}

.sectionNavActive {
  @apply font-semibold text-interfaceColor-100;
}

.sectionNavActive svg {
  display: none;
}

/* Chrome and Edge */
::-webkit-scrollbar {
  @apply h-[5px] w-[5px] md:w-[8px];
}

::-webkit-scrollbar-track {
  @apply bg-interfaceColor-20;
}

::-webkit-scrollbar-thumb {
  @apply rounded-[4px] bg-interfaceColor-60;
}

::-webkit-scrollbar-thumb:hover {
  @apply bg-interfaceColor-70;
}

/* Firefox */
/* Note: Firefox does not support customizing the scrollbar width */

/* Firefox on macOS */
* {
  scrollbar-width: thin;
  scrollbar-color: #a7a9ac #f4f4f4;
}

/* Firefox on Windows */
* {
  scrollbar-width: thin;
  scrollbar-color: #a7a9ac transparent;
}

/* Safari */
/* Note: Safari does not support customizing the scrollbar color */

/* Safari on macOS */
* {
  scrollbar-width: thin;
}

/* Safari on Windows */
* {
  scrollbar-width: thin;
}

/* Swiper Setup. (Image Carousel) */
.swiper-zoom-container {
  border-radius: 10px !important;
  overflow: hidden !important;
}

.swiper-wrapper {
  max-width: 360px;
}

.swiper-container {
  max-width: 320px !important;
  overflow: hidden !important;
  margin-top: 30px !important;
  margin: auto !important;

  overflow: visible;
  width: 70%;
  margin-left: auto !important;
  margin-right: auto !important;
}

.swiper-zoom-container {
  max-width: 360px !important;
}

.swiper-button-prev,
.swiper-button-next {
  border-radius: 6px;
  padding: 20px;
  padding-top: 32px;
  color: black !important;
  fill: black !important;
  font-size: small;
  width: 2rem !important;
  height: 2rem !important;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  width: 2rem !important;
  height: 2rem !important;
  font-size: 1rem !important;
}

.swiper-button-prev {
  margin-left: -15px;
}
.swiper-button-next {
  margin-right: -15px;
}

.react-datepicker {
  box-shadow: 0px 2px 6px 0px rgba(40, 43, 47, 0.12),
    0px 4px 20px 0px rgba(40, 43, 47, 0.14);
  border: none !important;
}

.react-datepicker__header {
  border: none !important;
  background-color: #ffffff !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #282b2f !important;
  font-size: 16px !important;
  font-family: 'Roobert', sans-serif !important;
  font-weight: 600 !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #657482 !important;
  width: 2rem !important;
  font-family: 'Roobert', sans-serif !important;
  margin: 6px !important;
}

.react-datepicker__day {
  color: #191d48 !important;
  font-size: 14px !important;
  margin: 6px !important;
}

.react-datepicker__day.react-datepicker__day--outside-month {
  color: #a7a9ac !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  background-color: #e9eced !important;
  font-weight: 600;
}

.react-datepicker__month .react-datepicker__month-text,
.react-datepicker__month .react-datepicker__quarter-text {
  width: 7.9rem !important;
  padding: 8px 12px;
  font-size: 14px !important;
}

.react-datepicker__month-text--selected,
.react-datepicker__year-text--selected {
  color: #191d48 !important;
}

.react-datepicker__year-wrapper {
  max-width: 261px !important;
}

.react-datepicker__year .react-datepicker__year-text {
  width: 5rem !important;
  margin: 2px auto !important;
  text-align: center !important;
  padding: 8px 12px;
  font-size: 14px !important;
}

.react-datepicker-popper {
  z-index: 9999 !important;
}

.react-datepicker__portal {
  align-items: end !important;
  background-color: rgb(13 13 13 / 20%) !important;
}

.react-datepicker__portal .react-datepicker {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
}

.react-datepicker__day--disabled,
.react-datepicker__month-text--disabled,
.react-datepicker__quarter-text--disabled,
.react-datepicker__year-text--disabled {
  color: #a7a9ac !important;
}

.permisssion-level-badge {
  @apply rounded-[20px] border px-2 py-[2px];
}
.permission-level-High {
  @apply border-errorColor-100 bg-errorColor-10 text-errorColor-100;
}

.permission-level-Medium {
  @apply border-warningColor-100 bg-warningColor-10 text-interfaceColor-100;
}

.permission-level-Low {
  @apply border-successColor-100 bg-successColor-10 text-successColor-100;
}

.permission-level-Unclassifed {
  @apply border-infoColor-100 bg-infoColor-10 text-infoColor-100;
}

