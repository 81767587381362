.cardHeader {
  @apply mb-3 w-full text-20 leading-7;
}

.cardHeaderContainer {
  @apply flex w-full flex-row items-baseline justify-between;
}

.cardContent {
  @apply flex h-full w-full justify-start align-baseline;
}
