.rightSpanWithBorder {
  @apply absolute right-0 top-0 h-full w-px border-r border-solid border-interfaceColor-30;
}

.rightSpanWithShadowHeader {
  @apply absolute right-0 top-[-5px] h-[calc(100%+20px)] w-[20px] shadow-right-only;
}

.rightSpanWithShadow {
  @apply absolute right-0 top-[-10px] h-[93px] w-[20px] shadow-right-only;
}

