.wrapper {
  @apply relative mt-[-38px] flex w-full flex-col lg:flex-row;
}

.sectionNavWrapper {
  @apply fixed w-[calc(100%-8px)] pt-[6px] md:w-[calc(100%-48px)] lg:mr-16 lg:w-[284px] lg:pt-0;
}

.sectionsWrapper {
  @apply mt-14 w-full pl-[1px] lg:ml-[330px] lg:mt-0 lg:w-[664px];
}

.h1 {
  @apply mb-3 mt-5 text-28 leading-9;
}

.h2 {
  @apply text-20;
}

.h4 {
  @apply text-18;
}

.hero {
  @apply mt-6 bg-interfaceColor-20 p-5 lg:mt-0;
}

.hero h1 {
  @apply mb-3 mt-3 text-28 leading-9;
}

.paragraph {
  @apply my-3;
}

.list li {
  @apply p-2;
}

.list li::before {
  content: '\2022';
  @apply mr-2 font-bold text-primaryBrandColor-150;
}
