.subGroupItem {
  @apply relative ml-6 mt-2;
}

.subGroupItem::after {
  content: '';
  @apply absolute -left-[0.7rem] top-[2px] h-[11px] w-[9px] -rotate-90 rounded-tl-[50px] border-l-[1px] border-t-[1px] border-interfaceColor-70;
}

.subGroupWithParentId::before {
  content: '';
  @apply absolute -left-[0.75rem] -top-[5px] h-[100%] max-h-[calc(100%-13px)] w-[1px] bg-interfaceColor-70;
}

.subGroupWithoutParentId::before {
  content: '';
  @apply absolute -left-[0.75rem] -top-[5px] h-[10px] max-h-[calc(100%)] w-[1px] bg-interfaceColor-70;
}
