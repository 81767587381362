.pageGrid {
  @apply mx-1 mt-7 grid grid-cols-1 gap-8 py-6 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-4;
}

.lineChartTile {
  @apply col-span-2 h-auto !w-auto bg-white md:col-span-3 md:h-[16.6rem] lg:col-span-2 xl:col-span-2;
}

.doughnutChartTile {
  @apply col-span-2 h-auto bg-white md:h-[16.6rem] lg:col-span-1 xl:col-span-1;
}
